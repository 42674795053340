import {Inject, Injectable} from '@angular/core';
import {BaseRepository, PushNotificationReceipt} from '@mcv/core';
import {HttpClient} from '@angular/common/http';
import {BASEURL} from '@mcv/config';

@Injectable({
    providedIn: 'root'
})
export class PushNotificationReceiptService extends BaseRepository<PushNotificationReceipt> {
    model = 'push-notification-receipt';

    constructor(
        protected httpClient: HttpClient,
        @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }
}
