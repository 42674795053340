import {Inject, Injectable} from '@angular/core';
import {BaseRepository, PayinWhiteList, SingleResult} from '@mcv/core';
import {HttpClient} from '@angular/common/http';
import {BASEURL} from '@mcv/config';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PayinWhiteListService extends BaseRepository<PayinWhiteList> {
    model = 'PayinWhiteList';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    getStatus(): Observable<SingleResult<boolean>> {
        const url = `${this.baseUrl}/${this.model}/isWhiteListEnabled`;
        return this.httpClient.get<SingleResult<boolean>>(url);
    }

    enablePayinWhiteList(): Observable<SingleResult<boolean>> {
        const url = `${this.baseUrl}/${this.model}/enableWhiteList`;
        return this.httpClient.patch<SingleResult<boolean>>(url, null);
    }

    disablePayinWhiteList(): Observable<SingleResult<boolean>> {
        const url = `${this.baseUrl}/${this.model}/disableWhiteList`;
        return this.httpClient.patch<SingleResult<boolean>>(url, null);
    }

}
