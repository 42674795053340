import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CseCategoryService} from '@mcv/coreservices';
import {map} from 'rxjs/operators';

@Component({
    selector: 'mcv-category-select',
    templateUrl: './category-select.component.html',
    styleUrls: ['./category-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CategorySelectComponent),
            multi: true
        }
    ]
})
export class CategorySelectComponent implements OnInit, ControlValueAccessor {
    @Input() value: string = null;
    @Input() canAdd = false;
    @Input() disabled: boolean;
    categories: string[];

    constructor(private cseCategoryService: CseCategoryService) {
    }

    onChange: any = (val: number) => {
    };

    onTouch: any = (val: number) => {
    };

    async ngOnInit(): Promise<void> {
        if (!this.categories) {
            this.categories = await this.cseCategoryService
                .getList()
                .pipe(map(r => r.data))
                .pipe(map(r => r.map(d => d.name)))
                .toPromise();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    onSelectChange($event: string & { name: string }): void {
        if ($event && $event.name) {
            this.onChange($event.name);
        } else {
            this.onChange($event ? $event : null);
        }
    }
}
