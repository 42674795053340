<div class="container">
    <ng-container *ngIf="!cseEmploye">
        <h4 class="text-center text-primary">Activation de votre carte</h4>

        <div class="text-center m-0">
            <img class="shadowed m-3" src="{{baseUrl}}/img/carte-identifiant.png"
                 style="max-width: 80%; max-height: 250px;">
        </div>
        <form [formGroup]="activationForm">
            <div class="col-md-12">
                <p>Indiquez l'identifiant à 9 chiffres qui se trouve au dos de votre carte</p>
                <mcv-error-display [error]="error"></mcv-error-display>
                <div class="form-group">
                    <label>Identifiant à 9 chiffres</label>
                    <input aria-describedby="code-carte-help"
                           class="form-control"
                           data-cy="code"
                           formControlName="code"
                           pattern="\d*"
                           type="text">
                    <small class="form-text text-muted" id="code-carte-help">
                        Le code est inscrit au verso de votre carte
                    </small>
                </div>
            </div>
            <div *ngIf="improveSecurity"
                 class="d-flex justify-content-center mb-2">
                <re-captcha formControlName="recaptchaReactive"></re-captcha>
            </div>
            <div class="text-center">
                <button (click)="checkCode()"
                        [disabled]="!activationForm.valid || checking"
                        [promiseBtn]="checking"
                        class="btn btn-primary rounded-pill pl-5 pr-5"
                        data-cy="submit"
                        type="submit">
                    Continuer
                </button>
            </div>
        </form>
    </ng-container>
</div>
