import { Inject, Injectable } from '@angular/core';
import { BaseRepository, VirementBeneficiary } from '@mcv/core';
import { HttpClient } from '@angular/common/http';
import { BASEURL } from '@mcv/config';

@Injectable({
    providedIn: 'root'
})
export class VirementOrderService extends BaseRepository<VirementBeneficiary> {
    model = 'virement-order';
    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }
}
