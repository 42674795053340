export enum CseEmployeState {
    New = 'new',
    NewWallet = 'new_wallet',
    NewCard = 'new_card',
    Preboarding = 'preboarding',
    PreboardingDone = 'preboarding_done',
    Onboarding = 'onboarding',
    Sync = 'sync',
    Updated = 'updated',
    Toclose = 'toclose',
    Closed = 'closed',
}

export const CseEmployeStates: Array<{ value: string, label: string }> = [
    {value: 'new', label: 'Nouveau (new)'},
    {value: 'new_wallet', label: 'Wallet à créer (new_wallet)'},
    {value: 'new_card', label: 'Carte à créer (new_card)'},
    {value: 'preboarding', label: 'En attente de preboarding (preboarding)'},
    {value: 'preboarding_done', label: 'Préboarding fait (preboarding_done)'},
    {value: 'onboarding', label: 'En attente d\'onboarding (onboarding)'},
    {value: 'sync', label: 'Ok (sync)'},
    {value: 'toclose', label: 'Compte à fermer (toclose)'},
    {value: 'updated', label: 'Compte en cours de mise à jour (updated)'},
    {value: 'closed', label: 'Compte fermé (closed)'},
]
