// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
export const environment = {
    client_env: 'dev',
    production: false,
    socketIo: 'https://ops.dev.macartevacances.fr:8000',
    promotionBaseUrl: 'https://api.promotion.macartevacances.com',
    baseUrl: 'https://ops.dev.macartevacances.fr/v1',
    CGU_MACARTEVACANCES: 'https://www.macartevacances.fr/conditions-generales',
    MENTIONS_MACARTEVACANCES: 'https://www.macartevacances.fr/mentions-legales',
    POLITIQUE_MACARTEVACANCES: 'https://www.macartevacances.fr/politique-de-confidentialite',
    CGU_TREEZOR: 'https://uploads-ssl.webflow.com/5dd310bf0e47ad35614be392/5f073a75aeed13aa59f12523_Treezor_%20Otium%20-%20CGU%20Carte.pdf',
    HIPAY_PUBLIC_LOGIN: '94684974.stage-secure-gateway.hipay-tpp.com',
    HIPAY_PUBLIC_PASSWORD: 'Test_0Fiu8ET1E4SxhNTlTQ9bY8gs',
    HIPAY_ENVIRONNEMENT: 'stage',
    G_TAG: 'UA-158358329-4',
    G_TAG_CSE: 'UA-158358329-4',
    G_TAG_PRO: 'UA-158358329-4',
    G_MAP: 'AIzaSyBcODGvzgaUASadSDqg2vZcNJoTMktBvfE',
    RECAPTCHA_SITE_KEY: '6LfGKO0UAAAAAFLClc2q5cnqQoEWX9m5PzYBRJ_N',
    INTERCOM: 'oo5boauo',
    firebase: {
        apiKey: 'AIzaSyCm7AA9VxiqLW_U82vs5jTTasBXAFqf4BY',
        authDomain: 'app-ios-dfdec.firebaseapp.com',
        databaseURL: 'https://app-ios-dfdec.firebaseio.com',
        projectId: 'app-ios-dfdec',
        storageBucket: 'app-ios-dfdec.appspot.com',
        messagingSenderId: '76113917472',
        appId: '1:76113917472:web:dfcd2b60693189c301ee45',
        measurementId: 'G-9W8RWJYJ8Z',
        remoteConfig: {
            settings: {
                minimumFetchIntervalMillis: 0
            },
            defaults: {
                kycliveness_enabled: true,
                other_offer_tab_visible: false,
                production_kycliveness_enabled: true,
                production_offer_tab_visible: false,
                test_kycliveness_enabled: true,
                test_offer_tab_visible: false,
                refund_enabled: false,
                refund_production_enabled: false,
                refund_test_enabled: false
            }
        }
    },
    featureFlagsNames: {
        kycLiveness: 'kycliveness_enabled',
        refundEnabled: 'refund_enabled'
    },
    HEAP_APP_ID: {
        CSE: '2037891132',
        CSE_EMPLOYE: '3009319557'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
