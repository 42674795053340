import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASEURL } from '@mcv/config';
import { AuthResult, SingleResult } from '@mcv/core';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MobileCheckService {

    constructor(
        @Inject(BASEURL) protected baseUrl: string,
        protected httpClient: HttpClient
    ) {}

    async verify(phone_number: string): Promise<any> {
        return this.httpClient.post(`${this.baseUrl}/cse-employe/cse-employe/verifyMobile`, { phone_number })
            .toPromise();
    }

    async confirmCode(phone_number: string, code: string): Promise<any> {
        return this.httpClient.post(`${this.baseUrl}/cse-employe/cse-employe/confirmMobileCheckCode`, { code, phone_number })
            .toPromise();

    }
}
