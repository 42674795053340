import {Pipe, PipeTransform} from '@angular/core';
import {CseEmploye} from '@mcv/core';

@Pipe({
    name: 'category'
})
export class CategoryPipe implements PipeTransform {

    transform(value: CseEmploye, ...args: unknown[]): string {
        if (value.cse_category) {
            return `<span class='badge badge-success'><i class='fas fa-tag fa-fw mr-1'></i>${value.cse_category.name}</span>`;
        }

        return '';
    }

}
