import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {BaseRepository, SingleResult} from '@mcv/core';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends BaseRepository<any> {
    protected model = 'notification';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    sendNotificationToCseEmployeOfCse(formData: any): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(`${this.baseUrl}/${this.model}/sendNotificationToCseEmployeOfCse`, formData);
    }

    sendNotificationToCseEmploye(formData: any): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(`${this.baseUrl}/${this.model}/sendNotificationToCseEmploye`, formData);
    }

    resendNotificiation(idReceipt: number): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/resendNotification/${idReceipt}`);
    }

}
