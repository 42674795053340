import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {BaseRepository, NewTpeName, SingleResult, Tpe, TpeStats} from '@mcv/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AffiliateTpeService extends BaseRepository<Tpe> {
    model = 'affiliate-user/affiliate-tpe'
    stats$: BehaviorSubject<TpeStats> = new BehaviorSubject<TpeStats>(null)

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl)
    }

    private _stats: TpeStats

    get stats(): TpeStats {
        return this._stats
    }

    set stats(stats: TpeStats) {
        this._stats = stats
        this.stats$.next(this._stats)
    }

    tpeStats(affiliateId: string): Observable<SingleResult<TpeStats>> {
        return this.httpClient.get<SingleResult<TpeStats>>(`${this.baseUrl}/${this.model}/stats?affiliateId=${affiliateId}`)
    }

    newTpename(affiliateId: string): Observable<SingleResult<NewTpeName>> {
        return this.httpClient.get<SingleResult<NewTpeName>>(`${this.baseUrl}/${this.model}/getNewTpeName?affiliateId=${affiliateId}`)
    }

}
