import {Component, Input, OnInit} from '@angular/core';

interface SearchItemResult {
    type: string;
    name: string;
    total: number;
    filter: string;
    payload?: string;
}

@Component({
    selector: 'mcv-search-result',
    templateUrl: './search-result.component.html',
    styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {
    @Input() prefixe: string;
    @Input() searchItem: SearchItemResult;
    badgeClass: string;
    descriptionText: string;
    iconClass = 'fa-tag';

    constructor() {
    }

    ngOnInit(): void {
        this.badgeClass = this.setBadgeClass();
        this.descriptionText = this.setDescriptionText();
        this.iconClass = this.setIconClass();
    }

    setBadgeClass() {
        switch (this.searchItem.type) {
        case 'category':
        case 'commande_category':
            return 'success';
        case 'import':
        case 'commande_without_card':
        case 'commande_with_card':
        case 'commande_cseemploye':
        case 'create_employe':
            return 'info';
        case 'cardinactive':
            return 'warning';
        case 'blocked':
            return 'danger';
        case 'outsider':
            return 'danger';
        case 'cseemploye':
            return 'info';
        case 'commande_with_outsider':
        case 'commande_without_outsider':
            return 'danger';
        default:
            return 'warning';
        }
    }

    setDescriptionText() {
        const plural = this.searchItem.total > 1 ? 's' : '';
        switch (this.searchItem.type) {
        case 'category':
            return `${this.searchItem.total} employé${plural} avec ce critère`;
        case 'import':
            return `${this.searchItem.total} employé${plural} attaché${plural} à ce fichier`;
        case 'commande_category':
            return `${this.prefixe} ${this.searchItem.total} employé${plural} depuis ce critère`;
        case 'commande_import':
            return `${this.prefixe} ${this.searchItem.total} employé${plural} depuis ce fichier`;
        case 'commande_without_card':
            return `${this.prefixe} ${this.searchItem.total} employé${plural} restant sans carte`;
        case 'commande_with_card':
            return `${this.prefixe} ${this.searchItem.total} employé${plural} restant qui ${plural ? 'ont' : 'a'} une carte`;
        case 'cardinactive':
            return `${this.searchItem.total} employé${plural} avec une carte non activée`;
        case 'commande_cseemploye':
            return this.searchItem.payload;
        case 'commande_with_outsider':
            return `${this.prefixe} ${this.searchItem.total} employé${plural} restant qui ${plural ? 'ne sont plus actifs' : 'n\'est plus actif'}`;
        case 'commande_without_outsider':
            return `${this.prefixe} ${this.searchItem.total} employé${plural} restant qui ${plural ? 'sont actifs' : 'est actif'}`;
        case 'blocked':
            return `${this.searchItem.total} employé${plural} ${plural ? 'bloqués' : 'bloqué'}`;
        case 'create_employe':
            return 'Créer un nouvel employé';
        case 'outsider':
            return `${this.searchItem.total} employé${plural} avec une carte indépendante`;
        default:
            return '';
        }
    }

    setIconClass() {
        switch (this.searchItem.type) {
        case 'commande_import':
            return 'fa-file-csv';
        case 'commande_without_card':
            return 'fa-circle';
        case 'commande_with_card':
            return 'fa-credit-card';
        case 'commande_cseemploye':
            return 'fa-user';
        case 'commande_with_outsider':
            return 'fa-users-slash';
        case 'commande_without_outsider':
            return 'fa-users';
        case 'create_employe':
            return 'fa-user-plus';
        case 'blocked':
            return 'fa-user-lock';
        case 'outsider':
            return 'fa-sign-out-alt';
        default:
            return 'fa-tag';
        }
    }
}
