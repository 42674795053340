import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'mcv-order-faq-redirect-button',
    templateUrl: './order-faq-redirect-button.component.html',
    styleUrls: ['./order-faq-redirect-button.component.scss']
})
export class OrderFaqRedirectButtonComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    goToLink(url: string) {
        window.open(url, '_blank');
    }
}
