import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'mcv-stepper-progress',
    templateUrl: './stepper-progress.component.html',
    styleUrls: ['./stepper-progress.component.scss']
})
export class StepperProgressComponent implements OnInit {
    @Input() steps: string[];
    @Input() active = 1;

    constructor() {
    }

    ngOnInit(): void {
    }

}
