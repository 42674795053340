import {Inject, Injectable} from '@angular/core';
import {BaseRepository, ContributionTopup} from '@mcv/core';
import {HttpClient} from '@angular/common/http';
import {BASEURL} from '@mcv/config';

@Injectable({
    providedIn: 'root'
})
export class ContributionTopupService extends BaseRepository<ContributionTopup> {
    model = 'contribution-topup';

    constructor(
        protected httpClient: HttpClient,
        @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }
}
