import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {SingleResult} from '@mcv/core';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StatsService {
    model = 'stats';

    constructor(private httpClient: HttpClient, @Inject(BASEURL) protected baseUrl: string) {
    }

    depensesByCat(csePublic: string): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/depensesByCat/${csePublic}`);
    }

    depenses(csePublic: string): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/depenses/${csePublic}`);
    }

    nbTransactions(csePublic: string): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/nbTransactions/${csePublic}`);
    }

    avgTransactions(csePublic: string): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/avgTransactions/${csePublic}`);
    }

    byCat(csePublic: string): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/byCat/${csePublic}`);
    }

    podium(csePublic: string): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/podium/${csePublic}`);
    }

    podiumByRegion(csePublic: string): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/podiumByRegion/${csePublic}`);
    }

    podiumWithCat(csePublic: string): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/podiumWithCat/${csePublic}`);
    }

    sources(csePublic: string): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/sources/${csePublic}`);
    }

    byPlaces(csePublic: string): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/byPlaces/${csePublic}`);
    }

    topup(csePublic: string): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/topup/${csePublic}`);
    }

    getAllStats(csePublic: string): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/getAllStats/${csePublic}`);
    }

    canDisplayStats(csePublic: string): Observable<SingleResult<boolean>> {
        return this.httpClient.get<SingleResult<any>>(`${this.baseUrl}/${this.model}/canDisplayStats/${csePublic}`);
    }
}
