import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@cse/app/auth/services/auth.service';
import { ConfigurationsService } from '@cse/app/auth/services/configurations.service';
import { StatsService } from '@cse/app/cse/stats/services/stats.service';
import { BASEURL } from '@mcv/config';
import { Cse, CseUser } from '@mcv/core';
import { Subscription } from 'rxjs';
import { Intercom } from 'ng-intercom';

// tslint:disable-next-line:prefer-const

@Component({
    selector: 'mcv-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
    user: CseUser;
    cse: Cse;
    menus_left: any[] = [];
    intercomOpen: boolean;
    isMessageCreated = false;
    nbMissingDocs = 0;
    private subscription: Subscription = new Subscription();

    constructor(@Inject(BASEURL) public baseUrl,
                private router: Router,
                private intercom: Intercom,
                private activatedRoute: ActivatedRoute,
                private statsService: StatsService,
                private configurationsService: ConfigurationsService,
                private authService: AuthService) {
        this.subscription.add(this.authService
            .cse$
            .asObservable()
            .subscribe(cse => {
                this.cse = cse;
                this.buildMenu();
                this.countMissingDocs();
            }));
        this.subscription.add(this.authService
            .cseUser$
            .asObservable()
            .subscribe(user => {
                this.user = user;
                this.countMissingDocs();
            }));
        this.buildMenu();
    }

    get isProfileRoute(): boolean {
        return this.router.url.startsWith('/cse/profile/user');
    }

    get isAboutRoute(): boolean {
        return this.router.url.startsWith('/cse/profile/about');
    }

    ngOnInit(): void {
        // @ts-ignore
        this.intercom.onHide(() => this.intercomOpen = false);
        // @ts-ignore
        this.intercom.onShow(() => this.intercomOpen = true);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    logout($event: MouseEvent) {
        $event.preventDefault();
        this.router.navigate(['/auth/logout']);
    }

    toggleIntercom($event: MouseEvent) {
        $event.preventDefault();
        // @ts-ignore
        this.intercomOpen ? this.intercom.hide() : this.intercom.showNewMessage();
    }

    toggleIntercomRefer($event: MouseEvent) {
        $event.preventDefault();
        if (this.isMessageCreated === false) {
            this.intercom.showNewMessage('Je souhaite obtenir des mois d\'abonnement gratuits en parrainant un CSE, pouvez-vous m\'en dire plus ?');
            this.isMessageCreated = true;
        } else {
            this.intercom.show();
        }
    }

    private countMissingDocs() {
        this.nbMissingDocs = [
            this.cse?.nb_missing_profile,
            this.cse?.missing_kyc_docs,
            this.user?.nb_missing_profile,
            this.user?.missing_kyc_docs
        ].reduce((acc, curr) => {
            if (curr && curr > 0) {
                acc++;
            }
            return acc;
        }, 0);

        this.nbMissingDocs += (this.cse?.contrat_cadre_accepted ? 0 : 1);
    }

    private buildMenu() {
        this.menus_left = [
            {
                title: 'Tableau de bord',
                link: '/cse/tableau-de-bord'
            },
            {
                title: 'Employés',
                link: '/cse/employes'
            },
            {
                title: 'Commandes',
                link: '/cse/commandes'
            }
        ];
        if (this.cse.pricing_display_stats && this.cse.can_display_stats) {
            this.menus_left.push({
                title: 'Statistiques',
                link: '/cse/stats'
            });
        }
        if (this.cse.pricing_perimetre_carte) {
            this.menus_left.push({
                title: 'Périmètre des cartes',
                link: '/cse/perimetre-cartes'
            });
        }
    }
}
