import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {
    Affiliate,
    AffiliateInscription,
    AuthResult,
    BaseRepository,
    Legal,
    PdfContrat,
    Result,
    SingleResult
} from '@mcv/core';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AffiliateInscriptionService extends BaseRepository<Affiliate> {
    model = 'affiliate-user/Affiliate';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    validStep1(formValue: any): Observable<SingleResult<boolean>> {
        return this.httpClient.post<SingleResult<boolean>>(`${this.baseUrl}/${this.model}/step1`, formValue);
    }

    validStep2(formValue: any): Observable<SingleResult<AffiliateInscription>> {
        return this.httpClient.post<SingleResult<AffiliateInscription>>(`${this.baseUrl}/${this.model}/step2`, formValue);
    }


    searchLegal(criteria: string): Observable<Result<Legal>> {
        return this.httpClient
            .get<Result<Legal>>(`${this.baseUrl}/${this.model}/findLegal?q=${criteria}`)
            .pipe(
                catchError(_ => of({success: false, data: []}))
            );
    }

    resendCode(formValue: any): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(`${this.baseUrl}/${this.model}/resendCode`, formValue);
    }

    checkCode(formValue: any): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(`${this.baseUrl}/${this.model}/checkCode`, formValue);
    }

    updateEmail(formValue: any): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(`${this.baseUrl}/${this.model}/updateEmail`, formValue);
    }

    getContrat(): Observable<SingleResult<PdfContrat>> {
        return this.httpClient.get<SingleResult<PdfContrat>>(`${this.baseUrl}/${this.model}/getLastContract`);
    }

    validContrat(formValue: any): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(`${this.baseUrl}/${this.model}/validContrat`, formValue);
    }

    finishInscription(formValue: any): Observable<SingleResult<AuthResult>> {
        return this.httpClient.put<SingleResult<AuthResult>>(`${this.baseUrl}/${this.model}/finishInscription`, formValue);
    }
}
