import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'mcv-svg-component',
    template: `<img class="svg" src="/assets/img/{{ file }}.svg" [alt]="file + ' icon'" [title]="title"/>`,
    styleUrls: ['./svg.component.scss']
})
export class SvgComponent implements OnInit {
    @Input() file: string;
    @Input() title: string;
    @Input() color = 'black';

    constructor() {
    }

    ngOnInit(): void {
    }

}
