import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MobilecheckRoutingModule } from './mobilecheck-routing.module';
import { IndexComponent } from './pages/index/index.component';
import { UiModule } from '@mcv/ui';
import { SharedModule } from '@cseemploye/app/shared/shared.module';
import { MobileCheckService } from '@cseemploye/service/mobile-check.service';
import { CoreservicesModule } from '@mcv/coreservices';


@NgModule({
    declarations: [IndexComponent],
    imports: [
        CommonModule,
        SharedModule,
        UiModule,
        CoreservicesModule,
        MobilecheckRoutingModule
    ]
})

export class MobilecheckModule {
}
