import {Pipe, PipeTransform} from '@angular/core';
import {CseEmploye} from '@mcv/core';

@Pipe({
    name: 'cseemployeSource'
})
export class CseemployeSourcePipe implements PipeTransform {

    transform(value: CseEmploye, ...args: unknown[]): string {
        if (value.cse_import) {
            return `<span class='badge badge-info badge-tiny'>
                        <i class='fas fa-file-csv fa-fw'></i>${value.cse_import.name}
                    </span>`;
        }
        return '';
    }
}
