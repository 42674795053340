import * as moment from "moment";

export function convertDatetime(object: any, fields: string[]): void {
    fields.forEach(f => {
        if (object && object[f]) {
            object[f] = moment(object[f])
                .utc()
                .format('YYYY-MM-DDTHH:mm:ssZ');
        }
    })
}

export function convertDate(object: any, fields: string[]): void {
    fields.forEach(f => {
        if (object && object[f]) {
            object[f] = moment(object[f])
                .format('YYYY-MM-DD');
        }
    })
}
