import { Component, Input, OnInit } from '@angular/core';
import { Address } from '@mcv/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { map } from 'rxjs/operators';
import { AddressService } from '@mcv/coreservices';
import { McvnotifierService } from '@mcv/ui';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'mcv-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit {
    @Input() private owner_public_id: string;
    @Input() private owner_type: string;
    @Input() private type: string;
    @Input() addressForm: FormGroup = this.fb.group({});
    @Input() fields: FormlyFieldConfig[];
    address: Address;

    constructor(
        private notifierService: McvnotifierService,
        private fb: FormBuilder,
        private addressService: AddressService
    ) {}

    ngOnInit(): void {
        this.loadAddress()
            .then((address: Address) => {
                this.address = address;
                this.initAddressForm();
            })
            .catch(err => {
                this.notifierService.error(err);
            });
    }

    async saveAddress(): Promise<any> {
        if (this.address === null) {
            this.addressForm.patchValue({
                owner_public_id: this.owner_public_id,
                owner_type: this.owner_type,
                type: this.type
            });
        }
        this.address = await this.addressService
            .add(this.addressForm.value)
            .pipe(map(r => r.data))
            .toPromise();
        this.initAddressForm();
    }

    private loadAddress(): Promise<Address> {
        return this.addressService
            .load(
                new HttpParams()
                    .set('public_id', this.owner_public_id)
                    .set('owner', this.owner_type)
                    .set('type', this.type)
            )
            .pipe(map(r => r.data))
            .toPromise();
    }

    private initAddressForm(): void {
        this.addressForm.patchValue(this.address);
        this.hydrateAddressForm();
    }

    private hydrateAddressForm(): void {
        if (this.address !== null) {
            let address2 = this.address.address2;
            if (this.address.address3) {
                address2 = address2 + this.address.address3;
                this.addressForm.patchValue({
                    address2: address2
                });
            }
        }
    }

}
