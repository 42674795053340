import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'mcv-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {
    @Input() badgeClass: string;

    constructor() {
    }

    ngOnInit(): void {
    }
}
