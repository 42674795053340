/**
 * 'new','prefilled','draft','draft_wait','draft_new','draft_kyc','draft_kyc_done','draft_wallet','sync','sync_toclose','closed'
 */
export const CseStates: Array<{ value: string, label: string }> = [
    {value: 'new', label: 'Nouveau (new)'},
    {value: 'prefilled', label: 'Pré-rempli (prefilled)'},
    {value: 'draft', label: 'En cours (draft)'},
    {value: 'draft_wait', label: 'CSE en cours de création chez Treezor (draft_wait)'},
    {value: 'draft_new', label: 'Compte admin en cours création chez Treezor (draft_new)'},
    {value: 'draft_wallet', label: 'Wallet CSE en cours création chez Treezor (draft_wallet)'},
    {value: 'draft_kyc', label: 'En attente des documents KYC (draft_kyc)'},
    {value: 'draft_kyc_done', label: 'KYC traité chez Treezor (draft_kyc_done)'},
    {value: 'sync', label: 'Compte OK (sync)'},
    {value: 'sync_toclose', label: 'Demande de fermeture du compte (sync_toclose)'},
    {value: 'closed', label: 'Compte fermé (closed)'},
]
