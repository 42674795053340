<nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top">
    <a [routerLink]="['/']" class="navbar-brand" style="font-size: 1.5rem;">
        <span class="align-text-top">macartevacances</span>
    </a>
    <button (click)="collapse.toggle()"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            class="navbar-toggler"
            data-target="#navbarSupportedContent"
            data-toggle="collapse"
            type="button">
        <span class="navbar-toggler-icon"></span>
    </button>

    <ngx-navbar-collapse #collapse="ngxNavbarCollapse" id="main-nav">
        <ul class="navbar-nav mr-auto" data-cy="mainNavList">
            <li *ngFor="let menu of menus_left" class="nav-item">
                <a class="nav-link" routerLink="{{menu.link}}" routerLinkActive="active">
                    <i *ngIf="menu.icon" class="far fa-fw {{menu.icon}}"></i>&nbsp;{{menu.title}}
                </a>
            </li>

            <li class="nav-item d-lg-none d-sm-list-item">
                <a [routerLink]="['/cse/profile/user']" class="nav-link" routerLinkActive="active">
                    &nbsp;Mon compte
                    <span *ngIf="nbMissingDocs > 0" class="badge badge-danger ml-2 mr-2">
                        {{nbMissingDocs}}
                    </span>
                </a>
            </li>
            <li class="nav-item d-lg-none d-sm-list-item">
                <a (click)="toggleIntercomRefer($event)"
                   href="#"
                   class="nav-link"
                >
                    &nbsp;Parrainez un CSE !
                </a>
            </li>
            <li (click)="toggleIntercom($event)" class="nav-item d-lg-none d-sm-list-item">
                <a class="nav-link" href="#">
                    &nbsp;Support
                </a>
            </li>
            <li class="nav-item d-lg-none d-sm-list-item">
                <a [routerLink]="['/cse/profile/about']" class="nav-link" routerLinkActive="active">
                    &nbsp;Informations
                </a>
            </li>
            <li (click)="logout($event)" class="nav-item d-lg-none d-sm-list-item">
                <a class="nav-link" href="#">
                    &nbsp;Déconnexion
                </a>
            </li>
        </ul>

        <ul class="navbar-nav float-right d-none d-lg-flex">
            <li class="nav-item navbar-pill mr-4" style="background-color:#ED6944;border: none;width: 199px">
                <a (click)="toggleIntercomRefer($event)"
                   href="#"
                   class="nav-link text-white"
                >
                    <img width="15" src="assets/img/wrapped-gift.png" class="img-fluid ml-2 mr-2" alt=""> Parrainez un CSE !
                </a>
            </li>
            <li class="nav-item navbar-pill">
                <a (click)="$event.preventDefault()"
                   [routerLinkActive]="'active'"
                   [routerLink]="['/cse/profile/user']"
                   aria-controls="dropdown-basic"
                   class="nav-link"
                >
                    <i class="fas fa-user ml-2 mr-2"></i>
                    Mon compte
                    <span *ngIf="nbMissingDocs > 0" class="badge badge-danger ml-2 mr-2">
                    {{nbMissingDocs}}
                </span>
                </a>
            </li>
            <li class="nav-item dropdown ml-2 mr-1" dropdown>
                <a (click)="$event.preventDefault()"
                   [ngClass]="isAboutRoute ? 'active':''"
                   aria-controls="dropdown-basic"
                   class="nav-link align-self-center"
                   data-cy="dropdown"
                   dropdownToggle
                   href="#"
                >
                    <i class="fas fa-question-circle fa-lg"></i>
                </a>
                <div *dropdownMenu aria-labelledby="dropdownMenuButton" class="dropdown-menu dropdown-menu-right"
                     id="dropdown-basic">
                    <a (click)="toggleIntercom($event)" class="dropdown-item" href="#">
                        <i class="fas fa-fw fa-comments mr-2"></i>
                        Support
                    </a>
                    <a [routerLink]="['/cse/profile/about']" class="dropdown-item" href="#">
                        <i class="fas fa-fw fa-info mr-2"></i>
                        Informations
                    </a>
                </div>
            </li>
            <li class="nav-item mr-2">
                <a (click)="logout($event)" class="nav-link" href="#" title="Se déconnecter">
                    <i class="fas fa-sign-out-alt fa-lg align-self-center"></i>
                </a>
            </li>
        </ul>
    </ngx-navbar-collapse>
</nav>
