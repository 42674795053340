import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ActivationService} from '@cseemploye/app/activation/services/activation.service';
import {AuthService} from '@cseemploye/app/auth/services/auth.service';
import {BASEURL} from '@mcv/config';
import {AuthResult, CseEmploye, OnboardingResult} from '@mcv/core';
import {McvnotifierService} from '@mcv/ui';
import {map} from 'rxjs/operators';

@Component({
    selector: 'mcv-card-ask-public-token',
    templateUrl: './card-ask-public-token.component.html',
    styleUrls: ['./card-ask-public-token.component.scss']
})
export class CardAskPublicTokenComponent implements OnInit {
    @Input() checkSecurity = false;
    @Output() confirm: EventEmitter<AuthResult> = new EventEmitter<AuthResult>();
    checking = false;
    activationForm: FormGroup = this.fb.group({
        code: [null, [Validators.pattern('^[0-9]{9}$'), Validators.required]],
        recaptchaReactive: [null]
    });
    cseEmploye: CseEmploye;
    improveSecurity = false;
    error: any;

    constructor(private fb: FormBuilder,
                @Inject(BASEURL) public baseUrl: string,
                private authService: AuthService,
                private router: Router,
                private notifierService: McvnotifierService,
                private activationService: ActivationService) {
    }

    ngOnInit(): void {
    }

    async checkCode(): Promise<void> {
        try {
            this.checking = true;
            const result: OnboardingResult = await this.activationService
                .onboarding(this.activationForm.value)
                .pipe(map(r => r.data))
                .toPromise();
            this.confirm.emit(result.jwt);
        } catch (e) {
            if (e.status === 422 && e.error.data.message) {
                e.error.data.message += ' <a href="/auth/login">Pour continuer, connectez-vous sur votre compte</a>';
            }
            this.error = e;
            if (this.checkSecurity) {
                this.improveSecurity = true;
                this.activationForm
                    .get('recaptchaReactive')
                    .setValidators([Validators.required]);
            }
        } finally {
            this.checking = false;
        }
    }
}
