import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ConfigurationsService } from '@cseemploye/app/auth/services/configurations.service';
import { CreateConfirmForms, CseEmploye } from '@mcv/core';
import { CseEmployeKycService, CseEmployeService } from '@mcv/coreservices';
import { McvnotifierService } from '@mcv/ui';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'mcv-cse-employe-personal-data-confirm',
    templateUrl: './cse-employe-personal-data-confirm.component.html',
    styleUrls: ['./cse-employe-personal-data-confirm.component.scss']
})
export class CseEmployePersonalDataConfirmComponent implements OnInit {
    @Input() cseEmploye: CseEmploye;
    @Input() kycEnabled: boolean;
    @Input() modalRefConfirm: BsModalRef;
    persoForm: FormGroup = CreateConfirmForms(this.fb);
    saving = false;
    kycUrl: string;
    loading = false;
    modalRef: BsModalRef;
    confirmPasswordForm: FormGroup = this.fb.group({
        public_id: [],
        password: []
    });

    constructor(private fb: FormBuilder,
                private notifierService: McvnotifierService,
                private configurationService: ConfigurationsService,
                private modalService: BsModalService,
                private cseEmployeKycService: CseEmployeKycService,
                private cseEmployeService: CseEmployeService) {
    }

    ngOnInit(): void {
        this.persoForm.patchValue(this.cseEmploye);
        this.persoForm.addControl('password_check', new FormControl([]));
    }

    async saveForm(): Promise<void> {
        try {
            this.saving = true;
            await this.cseEmployeService.add(this.persoForm.value)
                .pipe(
                    switchMap(_ => this.configurationService.loadAppConfig())
                )
                .toPromise();
            await this.cseEmployeService.updateTreezor(this.cseEmploye.public_id).toPromise();
            this.notifierService.success('Vos informations ont été enregistrées');
        } catch (e) {
            // 403 error already catch by the interceptor
            if (e.status !== 403) {
                this.notifierService.error(e);
            }
            this.ngOnInit();
        } finally {
            this.saving = false;
        }
    }

    checkPassword(confirmPasswordModale: TemplateRef<any>): void {
        if (this.persoForm.dirty) {
            this.modalRef = this.modalService.show(confirmPasswordModale, { class: 'modal-dialog-centered modal-lg' });
        } else {
            this.modalRefConfirm.hide();
            this.generateKycUrl();
        }
    }

    async confirmPassword(): Promise<void> {
        this.persoForm.patchValue({
            password_check: this.confirmPasswordForm.value.password
        });
        await this.saveForm();
        this.generateKycUrl();
        this.hide();
        this.modalRefConfirm.hide();
    }

    hide(): void {
        this.confirmPasswordForm.patchValue({ password: '' });
        this.modalRef.hide();
    }

    async generateKycUrl(): Promise<any> {
        if (this.kycEnabled === false) {
            return;
        }

        this.loading = true;
        const location = window.location.origin + '/client/compte/topup';
        this.cseEmployeKycService.generateKycUrl(location)
            .pipe(map(r => r.data))
            .toPromise()
            .then((res: any) => {
                this.kycUrl = res.url;
                window.location.href = this.kycUrl;
                this.loading = false;
            });
    }
}
