<div class="card border-light mt-4">
    <div class="card-body">
        <h2 class="mt-1 mb-3">Changement du mot de passe</h2>
        <form (ngSubmit)="savePassword()" [formGroup]="passwordForm" class="form-horizontal">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label class="control-label" for="employe-email">Identifiant</label>
                    <input autocomplete="username" class="form-control" id="employe-email" name="email"
                           readonly type="email" value="{{cseUser.email}}">
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label>Ancien mot de passe</label>
                    <mcv-password-input [prefilled]="prefilled" formControlName="oldpassword"></mcv-password-input>
                    <val-errors controlName="oldpassword"></val-errors>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-6">
                    <label>Nouveau du mot de passe</label>
                    <mcv-password-input [prefilled]="prefilled" formControlName="password"></mcv-password-input>
                    <val-errors controlName="password"></val-errors>
                </div>
                <div class="form-group col-md-6">
                    <label>Confirmation du nouveau mot de passe</label>
                    <mcv-password-input [prefilled]="prefilled" formControlName="password2"></mcv-password-input>
                    <val-errors controlName="password2"></val-errors>
                </div>
            </div>

            <div>
                <small class="form-text text-muted" id="password-help">
                    Le mot de passe doit inclure au moins un chiffre, une lettre en majuscule, un caractère
                    spécial et
                    avoir une longueur minimum de 8
                    caractères.
                </small>
            </div>

            <div class="form-row mt-3">
                <div class="col-md-6 offset-md-3">
                    <button [disabled]="prefilled || !passwordForm.valid || saving"
                            [promiseBtn]="saving"
                            class="btn btn-primary btn-block"
                            type="submit">
                        Modifier le mot de passe
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
