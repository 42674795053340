<ng-select (blur)="onTouch()"
           (change)="onSelectChange($event)"
           [addTag]="canAdd"
           [disabled]="disabled"
           [items]="categories"
           [ngModel]="value"
           addTagText="Créer le critère"
           appendTo="body"
           bindLabel="name"
           bindValue="name"
           notFoundText="Critère non trouvé">
</ng-select>
