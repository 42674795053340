import {Inject, Injectable} from "@angular/core";
import {BaseRepository, Result, SingleResult, TemplateEmail} from "@mcv/core";
import {HttpClient} from "@angular/common/http";
import {BASEURL} from "@mcv/config";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class EmailService extends BaseRepository<any> {
    protected model = 'email';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    getListTemplates(): Observable<Result<string>> {
        return this.httpClient.get<Result<string>>(`${this.baseUrl}/${this.model}/getListTemplates`);
    }

    getTemplate(formData: any): Observable<SingleResult<TemplateEmail>> {
        return this.httpClient.post<SingleResult<TemplateEmail>>(`${this.baseUrl}/${this.model}/getTemplate`, formData);
    }

    sendTestMail(formData: any): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(`${this.baseUrl}/${this.model}/sendTestEmail`, formData);
    }

}
