import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {BaseRepository, OrderFollowUpFullStatus, OrderFollowUpLastStatus, SingleResult} from '@mcv/core';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OrderFollowupService extends BaseRepository<any> {
    model = 'order-follow-up';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    getLastStatus(code: string): Observable<SingleResult<OrderFollowUpLastStatus>> {
        const url = `${this.baseUrl}/${this.model}/getOrderFollowUpLastStatus?code=` + encodeURI(code);
        return this.httpClient.get<SingleResult<OrderFollowUpLastStatus>>(url);
    }

    getFullStatus(code: string): Observable<SingleResult<OrderFollowUpFullStatus>> {
        const url = `${this.baseUrl}/${this.model}/getOrderFollowUpLastStatus?code=` + encodeURI(code);
        return this.httpClient.get<SingleResult<OrderFollowUpFullStatus>>(url);
    }
}
