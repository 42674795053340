import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'iban'
})
export class IbanPipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): string {
        return value.split(' ').join('').replace(/(.{4})/g, '$1 ');
    }
}
