export enum PaymentStatut {
    AUTHORIZED = 'A',
    DECLINED = 'I',
    REVERSED = 'V',
    SETTLED = 'S',
    REFUND = 'R',
    CLEARED = 'C'
}

const PaymentStatuts: Array<{ value: string, label: string, color: string }> = [
    {value: 'A', label: 'Autorisé', color: 'success'},
    {value: 'I', label: 'Refusé', color: 'danger'},
    {value: 'V', label: 'Reversé', color: 'success'},
    {value: 'S', label: 'Encaissé', color: 'success'},
    {value: 'R', label: 'Remboursé', color: 'warning'},
    {value: 'C', label: 'Ignoré', color: 'basic'},
]

export function getInfosForPaymentStatut(paymentStatut: string): { value: string, label: string, color: string } {
    return PaymentStatuts.find(p => p.value === paymentStatut);
}
