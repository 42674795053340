import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {AffiliateUser, AuthResult, BaseRepository, PdfContrat, Result, SingleResult} from '@mcv/core';
import {Observable} from 'rxjs';

interface PreBoardingResult {
    jwt: AuthResult,
    affiliateUser: AffiliateUser
}

@Injectable({
    providedIn: 'root'
})
export class AffiliateUserService extends BaseRepository<AffiliateUser> {
    model = 'affiliate-user/affiliate-user'

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    password(id: string, formValue: any): Observable<Result<AffiliateUser>> {
        return this.httpClient.put<Result<AffiliateUser>>(`${this.baseUrl}/${this.model}/password/${id}`, formValue);
    }

    sendActivationLink(id: string): Observable<Result<AffiliateUser>> {
        return this.httpClient.get<Result<AffiliateUser>>(`${this.baseUrl}/${this.model}/sendActivationLink/${id}`);
    }

    preboarding(formValue: any): Observable<SingleResult<AffiliateUser>> {
        return this.httpClient.put<SingleResult<AffiliateUser>>(`${this.baseUrl}/affiliate-user/activation/preboarding`, formValue);
    }

    end(formValue: any): Observable<SingleResult<any>> {
        return this.httpClient.put<SingleResult<any>>(`${this.baseUrl}/affiliate-user/activation/end`, formValue);
    }

    getSignedContrat(userid: any): Observable<SingleResult<PdfContrat>> {
        return this.httpClient.post<SingleResult<PdfContrat>>(`${this.baseUrl}/${this.model}/getSignedContract`, userid)
    }
}
