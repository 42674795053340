import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ErrorHandler, Injectable, Injector, LOCALE_ID, NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from '@cse/app/AppRouting.module';
import { AuthModule } from '@cse/app/auth/auth.module';
import { SharedModule } from '@cse/app/shared/shared.module';
import { environment } from '@cse/environments/environment';
import { BASEURL } from '@mcv/config';
import { UiModule } from '@mcv/ui';
import { IntercomModule } from 'ng-intercom';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { AngularIbanModule } from 'angular-iban';

import { AppComponent } from './app.component';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { ServiceLocator } from '@mcv/coreservices';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { httpInterceptorProviders } from '@mcv/core';
import { MobilecheckModule } from '@cseemploye/app/mobilecheck/mobilecheck.module';

registerLocaleData(localeFr);

export function errorHandlerFactory() {
    return new BugsnagErrorHandler();
}


@Injectable()
export class MatPaginatorIntlFr extends MatPaginatorIntl {
    itemsPerPageLabel = 'Nombre d\'éléments par page';
    nextPageLabel = 'Page suivante';
    previousPageLabel = 'Page précédente';

    getRangeLabel = function (page, pageSize, length) {
        const of = 'sur';
        if (length === 0 || pageSize === 0) {
            return '0 ' + of + ' ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
    };
}

const providers: Provider[] = [
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: BASEURL, useValue: environment.baseUrl },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlFr },
    MatTableModule,
    MatSortModule
];
if (environment.client_env === 'dev' || environment.client_env === 'local') {
    providers.push(httpInterceptorProviders);
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        AuthModule,
        MobilecheckModule,
        CommonModule,
        BrowserAnimationsModule,
        BrowserModule,
        UiModule,
        NgxTrimDirectiveModule,
        FormsModule,
        MatTableModule,
        MatSortModule,
        AngularIbanModule,
        SharedModule,
        AppRoutingModule,
        RouterModule,
        NgxGoogleAnalyticsModule.forRoot(environment.G_TAG_CSE),
        NgxGoogleAnalyticsRouterModule,
        IntercomModule.forRoot({
            appId: environment.INTERCOM, // from your Intercom config
            updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
        }),
        ReactiveFormsModule
    ],
    providers: providers,
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(private injector: Injector) {    // Create global Service Injector.
        ServiceLocator.injector = this.injector;
    }
}
