export enum CseUserState {
    DRAFT = 'draft',
    PREFILLED = 'prefilled',
    DRAFTKYC = 'draft_kyc',
    DRAFTKYCDONE = 'draft_kyc_done',
    SYNC = 'sync',
    TOCLOSE = 'toclose',
    CLOSED = 'closed',
    NONE = 'none',
}

export const CseUserStates: Array<{ value: string, label: string }> = [
    {value: 'draft', label: 'Brouillon (draft)'},
    {value: 'prefilled', label: 'Pré-inscription (prefilled)'},
    {value: 'draft_kyc', label: 'En cours de KYC (draft_kyc)'},
    {value: 'draft_kyc_done', label: 'KYC validé par Treezor (draft_kyc_done)'},
    {value: 'sync', label: 'OK (sync)'},
    {value: 'toclose', label: 'A fermer (toclose)'},
    {value: 'closed', label: 'Fermé (closed)'},
    {value: 'none', label: 'None (none)'},
]
