export enum CseCommandeState {
    Draft = "draft",
    Created = "created",
    Validated = "validated",
    Paying = "paying",
    Payed = "payed",
    WorkingUser = "working_user",
    WorkingUserWallet = "working_user_wallet",
    WorkingUserCard = "working_user_card",
    WorkingCard = "working_card",
    WorkingCredit = "working_credit",
    Ordered = "ordered",
    Prepared = "prepared",
    Sent = "sent",
    Received = "received",
    Cancel = "cancel",
}
