import {Component, Input, OnInit} from '@angular/core';
import {CreatePasswordForm, Cse, CseUser} from '@mcv/core';
import {CseUserService} from '@mcv/coreservices';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {McvnotifierService} from '@mcv/ui';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'mcv-profile-password',
    templateUrl: './profile-password.component.html',
    styleUrls: ['./profile-password.component.scss']
})
export class ProfilePasswordComponent implements OnInit {
    @Input() cseUser: CseUser;
    @Input() cse: Cse;

    subscription: Subscription = new Subscription();
    passwordForm: FormGroup;
    saving: boolean;

    prefilled = false;

    constructor(private cseUserService: CseUserService,
                private notifierService: McvnotifierService,
                private activatedRoute: ActivatedRoute,
                private fb: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.cse.state === 'prefilled' ? this.prefilled = true : this.prefilled = false;
        this.passwordForm = CreatePasswordForm(this.fb);
        this.passwordForm.addControl('oldpassword', this.fb.control(null, [Validators.required]));
    }


    async savePassword(): Promise<void> {
        try {
            this.saving = true;
            this.passwordForm.patchValue({public_id: this.cseUser.public_id, email: this.cseUser.email});
            await this.cseUserService.password(this.cseUser.public_id, this.passwordForm.value)
                .toPromise();
            this.notifierService.success('Mot de passe modifié!');
            this.passwordForm.reset();
            this.passwordForm.get('oldpassword')
                .markAsUntouched();
            this.passwordForm.get('oldpassword')
                .markAsPristine();
            this.passwordForm.get('oldpassword')
                .setErrors(null);
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.saving = false;
        }
    }
}
