import {FormBuilder, FormGroup, Validators} from '@angular/forms';

export const CheckTpeValues = (group: FormGroup): any => {
    const terminalId: string = group.get('terminalId').value;
    const merchantId: string = group.get('merchantId').value;

    return (!!terminalId || !!merchantId) ? null : {required: true};
};

export const CreateTpeForm = (fb: FormBuilder): FormGroup => {
    return fb.group({
        name: [null, [Validators.required]],
        terminalId: [],
        merchantId: [],
        public_id: []
    }, {validators: CheckTpeValues})
};
