import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BASEURL} from '@mcv/config';
import {BaseRepository, PushNotificationMessage} from '@mcv/core';

@Injectable({
    providedIn: 'root'
})
export class PushNotificationMessageService extends BaseRepository<PushNotificationMessage> {
    model = 'push-notification-message';

    constructor(
        protected httpClient: HttpClient,
        @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }
}
